<template>
  <div>
    <div class="topImg"><img alt="" src="../../assets/Rectangle 50.png" /></div>
    <div class="leftImg"><img alt="" src="../../assets/Ellipse 18.png" /></div>

    <div class="banner">
      <div class="banner-left">
        <div class="banner-title1">
          Provide A <span style="color: #3fbac2">Perfect</span> Solutions
        </div>
        <div class="banner-title2">For Your Business</div>
        <div class="banner-desc">
          One-stop solutions for fiat on/off ramps to bridge the gap between
          local currencies and cryptocurrencies
        </div>
        <div class="banner-btn" @click="$router.push('/demo')">
          <img alt="" src="../../assets/Group 74.png" />
        </div>
      </div>
      <div class="banner-right">
        <div class="left-Img">
          <img alt="" src="../../assets/Ellipse 20.png" />
        </div>
        <img alt="" src="../../assets/Group 116.png" />
      </div>
    </div>
    <div class="title">Advantages of OpenC</div>
    <div class="desc mt-24">Eight dimensions escort your business</div>
    <div class="advInfo">
      <div class="advInfo-card" style="margin-left: 0">
        <div class="advInfo-card-left">
          <img alt="" src="../../assets/image 31.png" />
        </div>
        <div class="advInfo-card-right">
          <div class="advInfo-card-right-top">Globalization</div>
          <div class="advInfo-card-right-btm">
            High-quality on/off ramps worldwide to broaden your global reach
          </div>
        </div>
      </div>

      <div class="advInfo-card">
        <div class="advInfo-card-left">
          <img alt="" src="../../assets/image 32.png" />
        </div>
        <div class="advInfo-card-right">
          <div class="advInfo-card-right-top">Easy to Integrate</div>
          <div class="advInfo-card-right-btm">
            One-stop multi-gateway integrator, eliminating all the troubles may
            occur
          </div>
        </div>
      </div>
      <div class="advInfo-card">
        <div class="advInfo-card-left">
          <img alt="" src="../../assets/image 33.png" />
        </div>

        <div class="advInfo-card-right">
          <div class="advInfo-card-right-top">Comprehensive Analysis Tools</div>
          <div class="advInfo-card-right-btm">
            Multi-dimensional background analysis tools
          </div>
        </div>
      </div>
      <div class="advInfo-card" style="margin-left: 0">
        <div class="advInfo-card-left">
          <img alt="" src="../../assets/image 36.png" />
        </div>
        <div class="advInfo-card-right">
          <div class="advInfo-card-right-top">Low Fee</div>
          <div class="advInfo-card-right-btm">
            More competitive commission fee to set you apart
          </div>
        </div>
      </div>
      <div class="advInfo-card">
        <div class="advInfo-card-left">
          <img alt="" src="../../assets/image 35.png" />
        </div>
        <div class="advInfo-card-right">
          <div class="advInfo-card-right-top">Compliance</div>
          <div class="advInfo-card-right-btm">
            Omni-gateway compliance screening and review assistant
          </div>
        </div>
      </div>
      <div class="advInfo-card">
        <div class="advInfo-card-left">
          <img alt="" src="../../assets/image 34.png" />
        </div>
        <div class="advInfo-card-right">
          <div class="advInfo-card-right-top">Strict Risk Control</div>
          <div class="advInfo-card-right-btm">
            Strong risk monitoring and prevention system
          </div>
        </div>
      </div>
    </div>
    <div class="title margin290">Supported Cryptocurrencies</div>
    <div class="desc mt-24">We support almost all major digital assets</div>
    <div class="sup mt-20" ref="supRefs">
      <div class="sup-btn">
        <div class="sup-btn-btn">Discovery</div>
        <div class="sup-btn-btn" style="margin-right: 0">More</div>
      </div>
      <div
        class="sup-logo1"
        :class="[!supLogo2 ? 'supLogoAni11' : 'supLogoAni3']"
        v-show="supLogo1 || supLogo2"
      >
        <img alt="" src="../../assets/image 39.png" />
      </div>
      <div
        class="sup-logo2"
        :class="[!supLogo2 ? 'supLogoAni2' : 'supLogoAni6']"
        v-show="supLogo1 || supLogo2"
      >
        <img alt="" src="../../assets/image 38.png" />
      </div>
      <div
        class="sup-logo3"
        :class="[!supLogo2 ? 'supLogoAni1' : 'supLogoAni4']"
        v-show="supLogo1 || supLogo2"
      >
        <img alt="" src="../../assets/image 40.png" />
      </div>
      <div
        class="sup-logo4"
        :class="[!supLogo2 ? 'supLogoAni22' : 'supLogoAni6']"
        v-show="supLogo1 || supLogo2"
      >
        <img alt="" src="../../assets/image 44.png" />
      </div>
      <div
        class="sup-logo5"
        :class="[!supLogo2 ? 'supLogoAni1' : 'supLogoAni5']"
        v-show="supLogo1 || supLogo2"
      >
        <img alt="" src="../../assets/image 42.png" />
      </div>
      <div
        class="sup-logo6"
        :class="[!supLogo2 ? 'supLogoAni2' : 'supLogoAni6']"
        v-show="supLogo1 || supLogo2"
      >
        <img alt="" src="../../assets/image 43.png" />
      </div>
      <div
        class="sup-logo7"
        :class="[!supLogo2 ? 'supLogoAni33' : 'supLogoAni7']"
        v-show="supLogo1 || supLogo2"
      >
        <img alt="" src="../../assets/image 41.png" />
      </div>
      <div
        :class="[!supLogo2 ? 'supLogoAni1' : 'supLogoAni13']"
        v-show="supLogo1 || supLogo2"
        class="sup-logo9"
      >
        <img alt="" src="../../assets/coin/usdt.png" />
      </div>
      <div
        :class="[!supLogo2 ? 'supLogoAni22' : 'supLogoAni6']"
        v-show="supLogo1 || supLogo2"
        class="sup-logo10"
      >
        <img alt="" src="../../assets/coin/avax.png" />
      </div>
      <div
        :class="[!supLogo2 ? 'supLogoAni33' : 'supLogoAni11']"
        v-show="supLogo1 || supLogo2"
        class="sup-logo11"
      >
        <img alt="" src="../../assets/coin/cusd.png" />
      </div>
      <div
        :class="[!supLogo2 ? 'supLogoAni11' : 'supLogoAni10']"
        v-show="supLogo1 || supLogo2"
        class="sup-logo12"
      >
        <img alt="" src="../../assets/coin/tusd.png" />
      </div>
      <div
        :class="[!supLogo2 ? 'supLogoAni1' : 'supLogoAni9']"
        v-show="supLogo1 || supLogo2"
        class="sup-logo13"
      >
        <img alt="" src="../../assets/coin/fil.png" />
      </div>
      <div
        :class="[!supLogo2 ? 'supLogoAni2' : 'supLogoAni8']"
        v-show="supLogo1 || supLogo2"
        class="sup-logo14"
      >
        <img alt="" src="../../assets/coin/usdc.png" />
      </div>
      <div class="sup-logo8">
        <img alt="" src="../../assets/Group 21.svg" />
      </div>
    </div>
    <div class="payment">
      <div class="payment-left">
        <div class="payment-left-title1">Supported Payment Methods</div>
        <div class="payment-left-title2">
          OpenC integrates multiple payment methods worldwide
        </div>
        <div class="payment-left-title3">50+</div>
        <div class="payment-left-title4">
          Master card, Visa, SEPA, ApplePay, Bank Transfer, POLi, NPP, Enumis,
          Payid, Blueshyft, Flexepin, Interac, iDEAL, Paynow, fps, instapay,
          upi, ojek-id, viettel-pay, duit-now, pay- id, nz-bank-transfer, etc.
        </div>
      </div>
      <div class="mySwiper" ref="mySwiperRef">
        <div class="payment-right swiper-wrapper">
          <div class="payment-right-logo swiper-slide">
            <div class="payment-right-logo-img1">
              <img alt="" src="../../assets/Apple_Pay_logo.svg" />
            </div>
          </div>
          <div class="payment-right-logo swiper-slide">
            <div class="payment-right-logo-img2">
              <img alt="" src="../../assets/bank-transfer.svg" />
            </div>
          </div>
          <div class="payment-right-logo swiper-slide">
            <div class="payment-right-logo-img3">
              <img alt="" src="../../assets/Google_Pay_Logo_(2020).svg" />
            </div>
          </div>
          <div class="payment-right-logo swiper-slide">
            <div class="payment-right-logo-img3">
              <img alt="" src="../../assets/Visa_Inc._logo.svg" />
            </div>
          </div>
          <div class="payment-right-logo swiper-slide">
            <div class="payment-right-logo-img3">
              <img alt="" src="../../assets/mastercard-2.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title margin290">Countries that support currencies</div>
    <div class="desc mt-24 descWidth">
      Supported Fiat Currencies 100+ Fiat Currencies We support over 100 fiat
      currencies, including INR, THD, HKD, PHP, IDR, VND, AUD, CAD, EUR, JPY,
      GBP, CZK, DKK, NOK, PLN, RUB, SEK, SGD, TRY, KRW, USD, ZAR, ARS, NZD, MYR
    </div>
    <div class="address" ref="supRefs1">
      <div
        class="address-logo1"
        :class="[!supLogo4 ? 'supLogoAni1' : 'supLogoAni3']"
        v-if="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/ad.svg" />
      </div>
      <div
        class="address-logo2"
        :class="[!supLogo4 ? 'supLogoAni2' : 'supLogoAni6']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/ae.svg" />
      </div>
      <div
        class="address-logo3"
        :class="[!supLogo4 ? 'supLogoAni1' : 'supLogoAni9']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/af.svg" />
      </div>
      <div
        class="address-logo4"
        :class="[!supLogo4 ? 'supLogoAni2' : 'supLogoAni11']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/ag.svg" />
      </div>
      <div
        class="address-logo5"
        :class="[!supLogo4 ? 'supLogoAni1' : 'supLogoAni5']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/ai.svg" />
      </div>
      <div
        class="address-logo6"
        :class="[!supLogo4 ? 'supLogoAni2' : 'supLogoAni6']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/al.svg" />
      </div>
      <div
        class="address-logo7"
        :class="[!supLogo4 ? 'supLogoAni1' : 'supLogoAni5']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/am.svg" />
      </div>
      <div
        class="address-logo8"
        :class="[!supLogo4 ? 'supLogoAni1' : 'supLogoAni4']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/ao.svg" />
      </div>
      <div
        class="address-logo9"
        :class="[!supLogo4 ? 'supLogoAni1' : 'supLogoAni5']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/aq.svg" />
      </div>
      <div
        class="address-logo10"
        :class="[!supLogo4 ? 'supLogoAni1' : 'supLogoAni5']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/ar.svg" />
      </div>
      <div
        class="address-logo11"
        :class="[!supLogo4 ? 'supLogoAni1' : 'supLogoAni3']"
        v-if="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/ad.svg" />
      </div>
      <div
        class="address-logo12"
        :class="[!supLogo4 ? 'supLogoAni11' : 'supLogoAni6']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/ba.svg" />
      </div>
      <div
        class="address-logo13"
        :class="[!supLogo4 ? 'supLogoAni22' : 'supLogoAni7']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bb.svg" />
      </div>
      <div
        class="address-logo14"
        :class="[!supLogo4 ? 'supLogoAni33' : 'supLogoAni11']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bt.svg" />
      </div>
      <div
        class="address-logo15"
        :class="[!supLogo4 ? 'supLogoAni33' : 'supLogoAni9']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/be.svg" />
      </div>
      <div
        class="address-logo16"
        :class="[!supLogo4 ? 'supLogoAni22' : 'supLogoAni6']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bf.svg" />
      </div>
      <div
        class="address-logo17"
        :class="[!supLogo4 ? 'supLogoAni33' : 'supLogoAni8']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bj.svg" />
      </div>
      <div
        class="address-logo18"
        :class="[!supLogo4 ? 'supLogoAni22' : 'supLogoAni7']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bm.svg" />
      </div>
      <div
        class="address-logo19"
        :class="[!supLogo4 ? 'supLogoAni33' : 'supLogoAni8']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bl.svg" />
      </div>
      <div
        class="address-logo20"
        :class="[!supLogo4 ? 'supLogoAni22' : 'supLogoAni12']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bn.svg" />
      </div>
      <div
        class="address-logo21"
        :class="[!supLogo4 ? 'supLogoAni22' : 'supLogoAni9']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bo.svg" />
      </div>
      <div
        class="address-logo22"
        :class="[!supLogo4 ? 'supLogoAni11' : 'supLogoAni13']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bq.svg" />
      </div>
      <div
        class="address-logo23"
        :class="[!supLogo4 ? 'supLogoAni22' : 'supLogoAni7']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/br.svg" />
      </div>
      <div
        class="address-logo24"
        :class="[!supLogo4 ? 'supLogoAni22' : 'supLogoAni9']"
        v-show="supLogo3 || supLogo4"
      >
        <img alt="" src="../../assets/4x3/bs.svg" />
      </div>
    </div>
    <div class="mac">
      <div class="mac-title">Full Customization Solution</div>
      <div class="mac-circle">
        <img alt="" src="../../assets/Ellipse 20.png" />
      </div>
      <div class="mac-box">
        <div class="mac-box-left">
          <div class="mac-box-left-box">
            <div class="mac-box-left-box-left">
              <img
                class="border50"
                alt=""
                src="../../assets/Rectangle 39.png"
              />
            </div>
            <div class="mac-box-left-box-right">
              <div class="mac-box-left-box-right-top">Simple to Integrate</div>
              <div class="mac-box-left-box-right-btm">
                Corporate users can enjoy on/off ramps worldwide by integrating
                with us, saving your time and trouble of developmen
              </div>
            </div>
          </div>
          <div class="mac-box-left-box">
            <div class="mac-box-left-box-left">
              <img
                class="border50"
                alt=""
                src="../../assets/Rectangle 39 (1).png"
              />
            </div>
            <div class="mac-box-left-box-right">
              <div class="mac-box-left-box-right-top">Completely Compliant</div>
              <div class="mac-box-left-box-right-btm">
                All gateways we select are in strict compliance with local rules
                and regulations, making sure the security of users'assets
              </div>
            </div>
          </div>
          <div class="mac-box-left-box">
            <div class="mac-box-left-box-left">
              <img
                class="border50"
                alt=""
                src="../../assets/Rectangle 39 (2).png"
              />
            </div>
            <div class="mac-box-left-box-right">
              <div class="mac-box-left-box-right-top">Easy to Customize</div>
              <div class="mac-box-left-box-right-btm">
                Users can have special customization according to their demands,
                including gateways preference, localization choice and design
              </div>
            </div>
          </div>
        </div>
        <div class="mac-box-right">
          <img alt="" src="../../assets/Group 72.png" />
        </div>
      </div>
    </div>
    <div class="countries gateway">
      <div class="countries-title countries-title">Trusted By</div>
      <div ref="mySwiperRef1" class="gateway-content">
        <div class="gateway-flex flex-x flex-btn swiper-wrapper">
          <!-- <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/a.png" />
            </div>
          </div> -->
          <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/b.png" />
            </div>
          </div>
          <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/c.png" />
            </div>
          </div>
          <div class="gateway-box flex-x flex-btn flex-alc swiper-slide">
            <div class="gateway-img">
              <img alt="" src="../../assets/TokenPocket_Logo__ traverse.png" />
            </div>
          </div>
          <!-- <div class="gateway-box flex-x flex-btn flex-alc">
            <div class="gateway-img">
              <img alt="" src="../../assets/WechatIMG1990.png" />
            </div>
          </div>
          <div class="gateway-box flex-x flex-btn flex-alc">
            <div class="gateway-img">
              <img alt="" src="../../assets/WechatIMG1991.jpeg" />
            </div>
          </div> -->
        </div>
      </div>
      <div class="countries-title1">Sign up for our live Communications</div>
      <div class="countries-btn">
        <div class="countries-btn-left">Enter your email address</div>
        <div class="countries-btn-right">SUBSCRIBE</div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  name: "index",
  data() {
    return {
      currentIndex: 0,
      supLogo1: false,
      supLogo2: false,
      supLogo3: false,
      supLogo4: false,
    };
  },
  created() {
    // supRefs
    // console.log(this.$refs.supRefs.getBoundingClientRect().top)
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);
    new Swiper(this.$refs.mySwiperRef, {
      speed: 1000,
      loop: true,
      slidesPerView: 3,
      spaceBetween: 30,
      direction: "vertical",
      autoplay: {
        delay: 0, //自动切换的时间间隔，单位ms
      },
    });
    new Swiper(this.$refs.mySwiperRef1, {
      autoplay: {
        delay: 0, //自动切换的时间间隔，单位ms
      }, 
      speed: 4000,
	  freeMode: true,
      loop: true,
      slidesPerView : 3,
      spaceBetween: 30,
    });
  },
  methods: {
    handleScrollx() {
      var that = this;
      console.log("滚动高度", window.pageYOffset);
      let top1 = this.$refs.supRefs.getBoundingClientRect().top;
      let top1Height = this.$refs.supRefs.offsetHeight / 1.5;
      console.log();
      //Supported Cryptocurrencies动画
      // if (window.pageYOffset - (top1 + top1Height) < 0) {
      this.supLogo1 = true;
      setTimeout(() => {
        that.supLogo2 = true;
        this.supLogo1 = false;
      }, 2000);
      // }
      //国家动画
      let top2 = this.$refs.supRefs1.getBoundingClientRect().top;
      let top2Height = this.$refs.supRefs1.offsetHeight / 1.5;
      // if (window.pageYOffset - (top2 + top2Height) < 0) {
      this.supLogo3 = true;
      setTimeout(() => {
        that.supLogo4 = true;
        this.supLogo3 = false;
      }, 2000);
      // }
    },
    currentIndexPrev() {
      // this.currentIndex = this.currentIndex + val
      // console.log(this.currentIndex)
      this.$refs.swiperRef.prev();
    },
    currentIndexNext() {
      this.$refs.swiperRef.next();
    },
  },
};
</script>

<style lang="scss" scoped>
//.animateFadeOut{
//  animation: fadeOut;
//}
//@keyframes fadeOut {
//  from {
//    opacity: 1;
//  }
//
//  to {
//    opacity: 0;
//  }
//}
.swiper-wrapper {
  transition-timing-function: linear !important; //想好具体位置放到哪，得能替换！
}
@import "../../css/public.scss";
.leftImg {
  width: 400px;
  height: 410px;
  position: absolute;
  top: 0px;
}
.title {
  text-align: center;
  font-size: 56px;
  color: #000;
}
.border50 {
  border-radius: 50%;
  height: auto;
}
.descWidth {
  width: 870px;
  margin: 24px auto 0;
}

.desc {
  font-size: 24px;
  align-items: center;
  opacity: 0.8;
  text-align: center;
  font-family: fontFace1 !important;
}
.gateway-img {
  width: 80% !important;
  height: 70px;
  margin: 0 auto;
}
.margin290 {
  margin-top: 290px;
}

.advInfo {
  width: 1480px;
  margin: 0 auto;
  background: #f7f9f9;
  border-radius: 44px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 72px;
  box-sizing: border-box;

  &-card {
    width: 332px;
    display: flex;
    margin-left: 132px;
    margin-top: 72px;

    &-left {
      min-width: 67px;
      max-height: 67px;
      margin-right: 19px;
    }

    &-right {
      color: #000;
      width: 246px;

      &-top {
        font-size: 24px;
        padding-bottom: 12px;
      }

      &-btm {
        font-size: 18px;
        opacity: 0.6;
		color: #000000;
		font-family: fontFace1 !important;
      }
    }
  }
}

.topImg {
  width: 100%;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0;
}

.banner {
  padding-top: 305px;
  padding-left: 220px;
  padding-right: 220px;
  display: flex;

  &-left {
    width: 955px;
  }

  &-right {
    width: 602px;
    height: 572px;
    position: absolute;
    right: 175px;
    top: 252px;
    .left-Img {
      position: absolute;
      width: 320px;
      height: 330px;
      left: -180px;
    }
  }

  &-btn {
    width: 273px;
    height: 80px;
    margin-top: 120px;
    margin-bottom: 250px;
    img {
      cursor: pointer;
    }
  }

  &-title1,
  &-title2 {
    font-size: 64px;
    color: #000000;
  }

  &-desc {
    margin-top: 40px;
    font-size: 24px;
    color: #000000;
    opacity: 0.6;
    width: 731px;
  }
}

.sup {
  width: 1480px;
  margin: 41px auto 0;
  height: 800px;
  background: url("../../assets/Group 149.png") no-repeat;
  background-size: 100% 100%;
  position: relative;

  &-btn {
    display: flex;
    justify-content: center;
    margin-top: 56px;

    &-btn {
      width: 190px;
      height: 56px;
      margin-right: 100px;
      background: #3fbac2;
      color: #fff;
      text-align: center;
      line-height: 56px;
      border-radius: 50px;
      font-size: 18px;
    }
  }

  &-logo1 {
    width: 102 * 1px;
    height: 102 * 1px;
    position: absolute;
    top: 226px;
    left: 180px;
  }

  &-logo2 {
    width: 120 * 1px;
    height: 120 * 1px;
    position: absolute;
    top: 521px;
    left: 113px;
  }

  &-logo3 {
    width: 76 * 1.1px;
    height: 76 * 1.1px;
    position: absolute;
    top: 399px;
    left: 387px;
  }

  &-logo4 {
    width: 70 * 1px;
    height: 70 * 1px;
    position: absolute;
    top: 183px;
    left: 600px;
  }

  &-logo5 {
    width: 128 * 1px;
    height: 128 * 1px;
    position: absolute;
    top: 202px;
    left: 1096px;
  }

  &-logo6 {
    width: 10 * 0.3px;
    height: 10 * 0.3px;
    position: absolute;
    top: 496px;
    left: 1218px;
  }

  &-logo7 {
    width: 64 * 1.8px;
    height: 64 * 1.8px;
    position: absolute;
    top: 521px;
    left: 1064px;
  }

  &-logo8 {
    width: 240px;
    height: 180px;
    padding: 50px;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    img {
      position: relative;
      top: -20px;
    }
  }
  &-logo9 {
    width: 64 * 0.8px;
    height: 64 * 0.8px;
    position: absolute;
    top: 181px;
    left: 824px;
  }
  &-logo10 {
    width: 64 * 0.7px;
    height: 64 * 0.7px;
    position: absolute;
    top: 95px;
    left: 664px;
  }
  &-logo11 {
    width: 64 * 0.6px;
    height: 64 * 0.6px;
    position: absolute;
    top: 421px;
    left: 284px;
  }
  &-logo12 {
    width: 64 * 0.8px;
    height: 64 * 0.8px;
    position: absolute;
    top: 321px;
    left: 1064px;
  }
  &-logo13 {
    width: 64 * 0.8px;
    height: 64 * 0.8px;
    position: absolute;
    top: 351px;
    left: 614px;
  }
  &-logo14 {
    width: 64 * 0.7px;
    height: 64 * 0.7px;
    position: absolute;
    top: 501px;
    left: 1264px;
  }
}

.payment {
  margin-top: 300px;
  display: flex;
  padding: 0 240px;
  align-items: center;
  &-left {
    min-width: 700px;
    &-title1 {
      font-size: 56px;
      color: #000;
    }

    &-title2 {
      font-size: 24px;
      color: #000000;
      opacity: 0.6;
    }

    &-title3 {
      font-size: 96px;
      color: #000000;
      margin-top: 227px;
    }

    &-title4 {
      font-size: 24px;
      color: #000000;
      margin-top: 26px;
      opacity: 0.6;
    }
  }
  .mySwiper {
    height: 370px;
    min-width: 600px;
    overflow: hidden;
    margin-left: 40px;
    padding: 10px;
    border-radius: 5%;
  }
  &-right {
    &-logo {
      width: 517px;
      height: 115px;
      background: #ffffff;
      border-radius: 32px;
      padding: 8px auto 0;
      box-shadow: 0px 0px 30px #f0f0f0;
      //margin-left: 114px;

      &-img1 {
        width: 40%;
        height: 80%;

        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      &-img2 {
        width: 214px;
        height: 96px;
        margin: 0 auto;

        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      &-img3 {
        width: 244px;
        height: 104px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.address {
  width: 1480px;
  margin: 60px auto 280px;
  height: 800px;
  background: url("../../assets/image 23.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  & div {
    border-radius: 10px;
    overflow: hidden;
    & img {
      height: auto;
      width: 100%;
    }
  }
  &-logo1 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 55px;
    left: 337px;
  }

  &-logo2 {
    width: 100 * 0.8px;
    height: 70 * 0.8px;
    position: absolute;
    top: 48px;
    left: 646px;
  }

  &-logo3 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 81px;
    left: 976px;
  }

  &-logo4 {
    width: 99 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 270px;
    left: 240px;
  }

  &-logo5 {
    width: 99 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 258px;
    left: 776px;
  }

  &-logo6 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 292px;
    left: 1132px;
  }

  &-logo7 {
    width: 75px;
    height: 53px;
    position: absolute;
    top: 467px;
    left: 681px;
  }
  &-logo8 {
    width: 75px;
    height: 53px;
    position: absolute;
    top: 287px;
    left: 481px;
  }
  &-logo9 {
    width: 75px;
    height: 53px;
    position: absolute;
    top: 487px;
    left: 381px;
  }
  &-logo10 {
    width: 75px;
    height: 53px;
    position: absolute;
    top: 477px;
    left: 981px;
  }
  &-logo11 {
    width: 75px;
    height: 53px;
    position: absolute;
    top: 170px;
    left: 1181px;
  }
  &-logo12 {
    width: 75px;
    height: 53px;
    position: absolute;
    top: 487px;
    left: 1181px;
  }
  &-logo13 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 100px;
    left: 1387px;
  }
  &-logo14 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 190px;
    left: 887px;
  }
  &-logo15 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 69px;
    left: 167px;
  }
  &-logo16 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 460px;
    left: 487px;
  }
  &-logo17 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 360px;
    left: 777px;
  }
  &-logo18 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 88px;
    left: 1187px;
  }
  &-logo19 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 190px;
    left: 587px;
  }
  &-logo20 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 320px;
    left: 997px;
  }
  &-logo21 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 180px;
    left: 357px;
  }
  &-logo22 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 120px;
    left: 57px;
  }
  &-logo23 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 360px;
    left: 240px;
  }
  &-logo24 {
    width: 100 * 0.8px;
    height: 67 * 0.8px;
    position: absolute;
    top: 120px;
    left: 457px;
  }
}

.mac {
  padding: 108px 220px;
  position: relative;
  background: #f7f9f9;
  box-sizing: border-box;

  &-circle {
    position: absolute;
    width: 304px;
    height: 310px;
    right: 128px;
    top: 97px;
    background: linear-gradient(
      180deg,
      rgba(63, 186, 194, 0.49) 0%,
      rgba(169, 255, 155, 0) 100%
    );
    filter: blur(140px);
  }

  &-title {
    font-size: 56px;
    color: #000000;
  }

  &-box {
    display: flex;

    &-left {
      &-box {
        display: flex;
        margin-top: 85px;

        &-left {
          min-width: 80px;
          min-height: 80px;
          max-height: 80px;
          margin-bottom: 80px;
        }

        &-right {
          color: #000;
          margin-left: 60px;

          &-top {
            font-size: 24px;
            max-width: 286px;
          }

          &-btm {
            font-size: 18px;
            opacity: 0.6;
            max-width: 246px;
          }
        }
      }
    }

    &-right {
      width: 972px;
      position: absolute;
      top: 0;
      right: 148px;
      //top: 0;
    }
  }
}

.countries {
  &-title {
    margin-top: 270px;
    font-size: 56px;
    color: #000000;
    text-align: center;
  }

  &-img {
    width: 1482px;
    height: 186px;
    margin: 116px auto 306px;
  }

  &-title1 {
    font-size: 40px;
    color: #3fbac2;
    text-align: center;
  }

  &-btn {
    width: 776px;
    height: 96px;
    background: #f7f9f9;
    border-radius: 8px;
    display: flex;
    line-height: 96px;
    text-align: center;
    margin: 87px auto 306px;

    &-left {
      width: 550px;
      text-align: center;
      font-size: 24px;
      opacity: 0.4;
    }

    &-right {
      width: 226px;
      height: 82px;
      background: #3fbac2;
      border-radius: 8px;
      font-size: 24px;
      color: #ffffff;
      line-height: 82px;
      text-align: center;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.gateway {
  &-content {
    padding-top: 138px;
    padding-bottom: 383px;
  }

  width: 1136px;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 20px;
  padding-bottom: 260px;
  &-box {
    padding: 0 20px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 18px #f0f0f0;
    border-radius: 32px;
  }

  &-title {
    font-size: 48px;
    text-align: center;
    padding-top: 260px;
    padding-bottom: 115px;
    box-sizing: border-box;
  }

  &-img {
    width: 150px;
  }

  &-desc {
    font-size: 24px;
    margin-left: 11px;
  }
}
.supLogoAni1 {
  animation-name: mymove;
  animation-duration: 2.5s;
  animation-timing-function: linear;
}
.supLogoAni2 {
  animation-name: mymove;
  animation-duration: 3s;
  animation-timing-function: linear;
  //animation-iteration-count: infinite;
}
.supLogoAni11 {
  animation-name: mymove;
  animation-duration: 7s;
  animation-timing-function: linear;
}
.supLogoAni22 {
  animation-name: mymove;
  animation-duration: 8s;
  animation-timing-function: linear;
}
.supLogoAni33 {
  animation-name: mymove;
  animation-duration: 8s;
  animation-timing-function: linear;
}
.supLogoAni3 {
  animation-name: mymove1;
  animation-duration: 8.33s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni4 {
  animation-name: mymove1;
  animation-duration: 9.33s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni5 {
  animation-name: mymove1;
  animation-duration: 11.53s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni6 {
  animation-name: mymove1;
  animation-duration: 18.63s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni7 {
  animation-name: mymove1;
  animation-duration: 10.32s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni8 {
  animation-name: mymove1;
  animation-duration: 14.32s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni9 {
  animation-name: mymove1;
  animation-duration: 8.82s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni10 {
  animation-name: mymove1;
  animation-duration: 11.82s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni11 {
  animation-name: mymove1;
  animation-duration: 12.82s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni12 {
  animation-name: mymove1;
  animation-duration: 15.32s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.supLogoAni13 {
  animation-name: mymove1;
  animation-duration: 14.66s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes mymove {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  25% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.75;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes mymove1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.75;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }

  60% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@media screen and (max-device-width: 768px) {
  .title {
    font-size: 90px;
  }
  .desc {
    font-size: 50px;
  }
  .banner {
    padding-left: 100px;
    padding-right: 100px;
    display: block;
    &-left,
    &-desc {
      width: 100%;
      text-align: center;
    }
    &-btn {
      margin: 100px auto 0;
      width: 500px;
      height: 160px;
    }
    &-desc {
      font-size: 50px;
      padding: 0 120px;
      box-sizing: border-box;
    }
    &-title1 {
      margin-top: 160px;
    }
    &-title1,
    &-title2 {
      font-size: 90px;
    }
    &-right {
      position: relative;
      left: 0;
      top: 0;
      width: 90%;
      margin: 100px auto 300px;
      height: 1400px;
    }
  }
  .advInfo {
    width: 95%;
    &-card {
      width: 95%;
      margin-left: 0;
      &-left {
        min-width: 250px;
        min-height: 250px;
      }
      &-right {
        width: auto;
        margin-left: 20px;
        &-top {
          font-size: 80px;
        }
        &-btm {
          font-size: 70px;
          font-family: fontFace1 !important;
        }
      }
    }
  }
  .sup {
    width: 95%;
    margin: 0 auto;
    //height: 800px;
    transform: scale(1.1);
    margin-top: 200px;
    box-sizing: border-box;
    &-btn {
      position: relative;
      top: -100px;
      &-btn {
        width: 300px;
        height: 100px;
        line-height: 100px;
        font-size: 40px;
      }
    }
    &-logo1 {
      width: 102 * 2.5px;
      height: 102 * 2.5px;
      position: absolute;
      top: 226px;
      left: 180 * 2px;
    }

    &-logo2 {
      width: 120 * 2.5px;
      height: 120 * 2.5px;
      position: absolute;
      top: 521px;
      left: 113 * 2px;
    }

    &-logo3 {
      width: 76 * 2.5px;
      height: 76 * 2.5px;
      position: absolute;
      top: 399px;
      left: 587px;
    }

    &-logo4 {
      width: 70 * 2.5px;
      height: 70 * 2.5px;
      position: absolute;
      top: 193px;
      left: 800px;
    }

    &-logo5 {
      width: 128 * 1.8px;
      height: 128 * 1.8px;
      position: absolute;
      top: 202px;
      left: 1296px;
    }

    &-logo6 {
      width: 100 * 1.8px;
      height: 100 * 1.8px;
      position: absolute;
      top: 496px;
      left: 1400px;
    }

    &-logo7 {
      width: 64 * 2.5px;
      height: 64 * 2.5px;
      position: absolute;
      top: 521px;
      left: 1218px;
    }

    &-logo8 {
      width: 240px;
      height: 180px;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
      img {
        position: relative;
        top: -20px;
      }
    }
    &-logo9 {
      width: 64 * 0.8px;
      height: 64 * 0.8px;
      position: absolute;
      top: 231px;
      left: 1184px;
    }
    &-logo10 {
      width: 64 * 1px;
      height: 64 * 1px;
      position: absolute;
      top: 95px;
      left: 664px;
    }
    &-logo11 {
      width: 64 * 0.9px;
      height: 64 * 0.9px;
      position: absolute;
      top: 421px;
      left: 484px;
    }
    &-logo12 {
      width: 64 * 0.9px;
      height: 64 * 0.9px;
      position: absolute;
      top: 321px;
      left: 1064px;
    }
    &-logo13 {
      width: 64 * 1.2px;
      height: 64 * 1.2px;
      position: absolute;
      top: 321px;
      left: 614px;
    }
    &-logo14 {
      width: 64 * 1.3px;
      height: 64 * 1.3px;
      position: absolute;
      top: 471px;
      left: 1164px;
    }
  }
  .payment {
    display: block;
    text-align: center;
    padding: 0 70px;
    &-left {
      &-title1 {
        font-size: 80px;
      }
      &-title2,
      &-title4 {
        font-size: 50px;
        margin-top: 20px;
        font-family: fontFace1 !important;
      }
      &-title3 {
        font-size: 200px;
      }
    }
    .mySwiper {
      height: 1250px;
      padding-top: 200px;
      box-sizing: border-box;
      margin-top: 100px;
    }
    &-right {
      //height: 1000px;
      &-logo {
        margin: 0 auto;
        width: 80%;
        height: 300px !important;
      }
    }
  }
  .address {
    width: 90%;
    &-logo1 {
      width: 100 * 0.6px;
      height: 67 * 0.6px;
      position: absolute;
      top: 40px;
      left: 250px;
    }

    &-logo2 {
      width: 100 * 0.6px;
      height: 70 * 0.6px;
      position: absolute;
      top: 0px;
      left: 600px;
    }

    &-logo3 {
      width: 100 * 0.6;
      height: 67 * 0.6;
      position: absolute;
      top: 0px;
      left: 976px;
    }

    &-logo4 {
      width: 99 * 0.6;
      height: 67 * 0.6;
      position: absolute;
      top: 100px;
      left: 100px;
    }

    &-logo5 {
      width: 99 * 0.6;
      height: 67 * 0.6;
      position: absolute;
      top: 120px;
      left: 700px;
    }

    &-logo6 {
      width: 100 * 0.6;
      height: 67 * 0.6;
      position: absolute;
      top: 292px;
      left: 1132px;
    }

    &-logo7 {
      width: 75px * 0.6;
      height: 40px * 0.6;
      position: absolute;
      top: 580px;
      left: 450px;
      img {
        border-radius: 12px;
      }
    }
    &-logo8 {
      width: 75px;
      height: 53px;
      position: absolute;
      top: 287px;
      left: 481px;
    }
    &-logo9 {
      width: 75px;
      height: 53px;
      position: absolute;
      top: 487px;
      left: 381px;
    }
    &-logo10 {
      width: 75px;
      height: 53px;
      position: absolute;
      top: 477px;
      left: 981px;
    }
    &-logo11 {
      width: 75px;
      height: 53px;
      position: absolute;
      top: 170px;
      left: 1181px;
    }
    &-logo12 {
      width: 75px;
      height: 53px;
      position: absolute;
      top: 487px;
      left: 1181px;
    }
    &-logo13 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 100px;
      left: 1387px;
    }
    &-logo14 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 190px;
      left: 887px;
    }
    &-logo15 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 280px;
      left: 1367px;
    }
    &-logo16 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 460px;
      left: 487px;
    }
    &-logo17 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 360px;
      left: 777px;
    }
    &-logo18 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 88px;
      left: 1187px;
    }
    &-logo19 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 190px;
      left: 587px;
    }
    &-logo20 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 320px;
      left: 997px;
    }
    &-logo21 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 180px;
      left: 357px;
    }
    &-logo22 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 120px;
      left: 887px;
    }
    &-logo23 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 360px;
      left: 240px;
    }
    &-logo24 {
      width: 100 * 0.8px;
      height: 67 * 0.8px;
      position: absolute;
      top: 120px;
      left: 457px;
    }
  }
  .mac {
    padding: 108px 100px;
    &-title {
      text-align: center;
    }
    &-box {
      display: block;
      &-left {
        &-box {
          width: 100%;
        }
        &-box-left {
          width: 250px;
          margin-top: 20px;
          height: 250px;
          min-height: auto;
          min-width: 250px;
          max-height: 300px;
        }
        &-box-right {
          &-top {
            font-size: 80px;
            max-width: 100%;
          }
          &-btm {
            max-width: 100%;
            font-size: 50px;
            font-family: fontFace1 !important;
          }
        }
      }
      &-right {
        position: relative;
        top: inherit;
        right: inherit;
        width: 90%;
        margin: 0 auto;
      }
    }
  }
  .countries {
    width: 90%;
    .gateway {
      &-img {
        width: 100% !important;
        height: 65%;
      }
      &-box {
        width: 500px !important;
        height: 200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }
    }
    &-title1 {
      font-size: 60px;
    }
    &-btn {
      width: 1500px;
      height: 150px;
      line-height: 150px;
      &-left {
        width: 70%;
        font-size: 60px;
      }
      &-right {
        width: 29%;
        font-size: 50px;
        height: 140px;
        line-height: 140px;
      }
    }
  }
}
</style>
